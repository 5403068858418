import React from "react";
import { Button, Card, Col, Form, Input, Row, message } from "antd";
import { SaveOutlined } from "@ant-design/icons";

import { TChangePassword } from "../types/TChangePassword";

import * as UserAction from "../actions/user.action";
import { getUser } from "../services/localStorage";

const ChangePasswordPage: React.FC = () => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <>
      <div className="tabled">
        <Row typeof="flex" justify="center" align={"middle"}>
          <Col span={8}>
            <Card
              bordered={false}
              title="Changes Password"
              extra={
                <Button
                  type="primary"
                  ghost
                  onClick={() => form.submit()}
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
              }
            >
              {contextHolder}
              <Form
                form={form}
                layout={"vertical"}
                size="small"
                onFinish={(formValues: any) => {
                  UserAction.updateUser(formValues)
                    .then(() => {
                      messageApi.open({
                        type: "success",
                        content: "Password has been successfully changed",
                      });
                      form.resetFields();
                    })
                    .catch((e: Error) => {
                      console.log(e);
                    });
                }}
                initialValues={{ username: getUser() }}
                autoComplete="off"
              >
                <Form.Item<TChangePassword> name="id" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item<TChangePassword> name="username" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item<TChangePassword>
                  label="Old Password"
                  name="oldPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your old password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item<TChangePassword>
                  label="New Password"
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your new password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item<TChangePassword>
                  name="confirm"
                  label="Confirm New Password"
                  dependencies={["newPassword"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The new password that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ChangePasswordPage;
