import { LoaderFunctionArgs, redirect } from "react-router-dom";

import { setTokens } from "../services/localStorage";
import * as AuthAction from "./auth.action";

export async function loginAction({ request }: LoaderFunctionArgs) {
  const data: any = Object.fromEntries(await request.formData());

  const response = await AuthAction.login(data);
  setTokens(response.data);

  let redirectTo = data.redirectTo as string | null;
  return redirect(redirectTo || "/");
}
