import { Navigate, useLocation } from "react-router-dom";
import { isAuthenticated } from "../services/authenticationService";
import Main from "../components/layouts/main";

const PrivateRoutes = () => {
  const location = useLocation();

  if (isAuthenticated() === undefined) {
    return null;
  }

  return isAuthenticated() ? (
    <Main />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

export default PrivateRoutes;
