import { AxiosResponse } from "axios";
import httpCommon from "./http-common";
import { TVehicle } from "../types/TVehicle";
import { LoaderFunctionArgs, redirect } from "react-router-dom";

export async function vehicleAction({ request }: LoaderFunctionArgs) {
  const data: any = Object.fromEntries(await request.formData());

  if (data?.action === "delete") {
    await deleteVehicle(data?.id);
  } else {
    data.id =
      data.id === undefined || data.id === "undefined" ? undefined : data.id;
    data.driver_id =
      data.driver_id === undefined || data.driver_id === "undefined"
        ? undefined
        : data.driver_id;

    data.id === undefined || data.id === "undefined"
      ? await createVehicle(data)
      : await updateVehicle(data);
  }

  return redirect("/vehicles");
}

export const getDatatables = async () => {
  const response: AxiosResponse = await httpCommon.get("/vehicle");
  return response.data as TVehicle[];
};

export const createVehicle = async (data: TVehicle) => {
  return await httpCommon.post<TVehicle>("/vehicle", data);
};

export const updateVehicle = async (data: TVehicle) => {
  return await httpCommon.put<TVehicle>("/vehicle/" + data.id, data);
};

export const deleteVehicle = async (id: string) => {
  return await httpCommon.delete<TVehicle>("/vehicle/" + id);
};
