import React, { useEffect } from "react";
import * as pkg from "@googlemaps/js-api-loader";
import { TVehicle } from "../types/TVehicle";
import { TOrder } from "../types/TOrder";
import { useSubmit } from "react-router-dom";

const { Loader } = pkg;

export function Map({
  markers,
  order,
}: {
  markers?: TVehicle[];
  order?: TOrder | undefined;
}) {
  const submit = useSubmit();
  const mapRef = React.useRef(null);

  const calculateAndDisplayRoute = async function (
    directionsService: any,
    directionsRenderer: any
  ) {
    try {
      const res = await directionsService.route({
        origin: {
          query:
            order?.sender_lat_back && order?.sender_lng_back
              ? `${order?.sender_lat_back},${order?.sender_lng_back}`
              : `${order?.sender_lat},${order?.sender_lng}`,
          // "-4.8828902321171395, 105.21360587237072",
        },
        destination: {
          query:
            order?.receiver_lat_back && order?.receiver_lng_back
              ? `${order?.receiver_lat_back},${order?.receiver_lng_back}`
              : `${order?.receiver_lat},${order?.receiver_lng}`,
          // "-4.1067328759970625, 104.16113604117484",
        },
        waypoints: order?.transits?.map((trans) => ({
          location: `${trans.lat},${trans.lng}`,
          stopover: true,
        })),
        // [
        //   {
        //     location: "-4.8349521,104.897095",
        //     // "-4.856001226307208, 104.92754996953286",
        //     stopover: true,
        //   },
        // ],
        travelMode: google.maps.TravelMode.DRIVING,
        avoidTolls: false,
        avoidHighways: true,
        provideRouteAlternatives: true,
        drivingOptions: {
          departureTime: new Date(),
          trafficModel: "pessimistic",
        },
      });

      if (order && !order.route) {
        order.id &&
          submit(
            {
              id: order?.id,
              route: JSON.stringify(
                res?.routes[0].overview_path.map((path: any) => ({
                  lat: path.lat(),
                  lng: path.lng(),
                }))
              ),
            },
            { method: "POST", action: "/orders" }
          );
      }

      if (
        order &&
        !order.route_back &&
        order.sender_lat_back &&
        order.sender_lng_back &&
        order.receiver_lat_back &&
        order.receiver_lng_back
      ) {
        order.id &&
          submit(
            {
              id: order?.id,
              route_back: JSON.stringify(
                res?.routes[0].overview_path.map((path: any) => ({
                  lat: path.lat(),
                  lng: path.lng(),
                }))
              ),
            },
            { method: "POST", action: "/orders" }
          );
      }

      directionsRenderer.setDirections(res);
    } catch (e: any) {
      console.log(`Directions request failed 
          Code: ${e.code} 
          Message: ${e.message} `);
    }
  };

  useEffect(() => {
    const initMap = async () => {
      const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY as string,
        version: "weekly",
      });
      const { Map } = await loader.importLibrary("maps");

      const position = {
        lat: markers
          ? markers.length === 0
            ? -6.2
            : parseFloat(markers[0].lat || "0")
          : -6.2,
        lng: markers
          ? markers.length === 0
            ? 106.816666
            : parseFloat(markers[0].lng || "0")
          : 106.816666,
      };

      const mapOptions: google.maps.MapOptions = {
        center: position,
        zoom: markers ? (markers.length === 1 ? 10 : 8) : 10,
        mapId: "MY_NEXTJS_MAPID",
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
      const map = new Map(
        mapRef.current as unknown as HTMLDivElement,
        mapOptions
      );

      const google = window.google; // Fix for ESLint Err google is not defined
      if (order) {
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();
        directionsRenderer.setOptions({
          polylineOptions: {
            strokeOpacity: 0.5,
            strokeColor: "#FF0000",
          },
        });
        directionsRenderer.setMap(map);
        calculateAndDisplayRoute(directionsService, directionsRenderer);
      }

      markers?.forEach((marker) => {
        const markerPin = new google.maps.Marker({
          map,
          position: {
            lat: marker ? parseFloat(marker.lat || "0") : 0,
            lng: marker ? parseFloat(marker.lng || "0") : 0,
          },
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: "#00F",
            fillOpacity: 1,
            strokeColor: "#00A",
            strokeOpacity: 1,
            strokeWeight: 1,
            scale: 7,
          },
        });

        // const contentString =
        //   '<div id="content"><div id="bodyContent">' +
        //   marker.license_plate +
        //   "</div></div>";

        const infowindow = new google.maps.InfoWindow({
          content: marker.license_plate,
          // ariaLabel: "Uluru",
        });

        markerPin.addListener("mouseover", () => {
          infowindow.open({
            anchor: markerPin,
            map,
          });
        });
        markerPin.addListener("mouseout", () => {
          infowindow.close();
        });
      });
    };
    initMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <>
      <script>alert("hi!")</script>
      <script src="https://maps.googleapis.com/maps/api/js"></script>

      <div
        style={{ height: "600px", outline: "none", borderRadius: 8 }}
        ref={mapRef}
      />
    </>
  );
}
