import { AxiosResponse } from "axios";
import httpCommon from "./http-common";
import { TOrder } from "../types/TOrder";
import { LoaderFunctionArgs, json, redirect } from "react-router-dom";
import { TTransit } from "../types/TTransit";

export async function orderAction({ request }: LoaderFunctionArgs) {
  const data: any = Object.fromEntries(await request.formData());

  if (data?.action === "delete") {
    await deleteOrder(data?.id);
  } else if (data?.action === "transit") {
    data.id = data.id === "undefined" ? undefined : data.id;
    const respond = data?.id
      ? await updateTransit(data)
      : await createTransit(data);
    return json({ data: respond.data });
  } else if (data?.action === "delete-transit") {
    const respond = await deleteTransit(data?.id);
    return json({ data: respond.data });
  } else {
    data.id =
      data.id === undefined || data.id === "undefined" ? undefined : data.id;
    data.sender_lat =
      data.sender_lat === undefined || data.sender_lat === "undefined"
        ? undefined
        : data.sender_lat;
    data.sender_lng =
      data.sender_lng === undefined || data.sender_lng === "undefined"
        ? undefined
        : data.sender_lng;
    data.receiver_lat =
      data.receiver_lat === undefined || data.receiver_lat === "undefined"
        ? undefined
        : data.receiver_lat;
    data.receiver_lng =
      data.receiver_lng === undefined || data.receiver_lng === "undefined"
        ? undefined
        : data.receiver_lng;
    data.pickup =
      data.pickup === undefined || data.pickup === "undefined"
        ? undefined
        : data.pickup;
    data.vehicle_id =
      data.vehicle_id === undefined ||
      data.vehicle_id === "undefined" ||
      data.vehicle_id === "null"
        ? undefined
        : data.vehicle_id;
    data.loader =
      data.loader === undefined || data.loader === "undefined"
        ? undefined
        : data.loader;
    data.status = !data.loader;
    data.status_pp =
      data.status_pp === undefined || data.status_pp === "undefined"
        ? undefined
        : data.status_pp === "true";
    console.log("orderAction : ", data);
    data.id === undefined || data.id === "undefined"
      ? await createOrder(data)
      : await updateOrder(data);
  }

  return redirect("/orders");
}

export const getDatatables = async () => {
  const response: AxiosResponse = await httpCommon.get("/order");
  return response.data as TOrder[];
};

export const createOrder = async (data: TOrder) => {
  return await httpCommon.post<TOrder>("/order", data);
};

export const updateOrder = async (data: TOrder) => {
  return await httpCommon.put<TOrder>("/order/" + data.id, data);
};

export const deleteOrder = async (id: string) => {
  return await httpCommon.delete<TOrder>("/order/" + id);
};

export const createTransit = async (data: TTransit) => {
  return await httpCommon.post<TTransit>("/transit", data);
};

export const updateTransit = async (data: TTransit) => {
  return await httpCommon.put<TTransit>("/transit/" + data.id, data);
};

export const deleteTransit = async (id: string) => {
  return await httpCommon.delete<TTransit>("/transit/" + id);
};
