import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Modal,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  PlusCircleOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";

import { TAccount } from "../types/TAccount";

import { useRouteLoaderData, useSubmit } from "react-router-dom";

const AccountPage: React.FC = () => {
  let { dataSource } = useRouteLoaderData("user") as {
    dataSource: TAccount[] | [];
  };

  const [modal2Open, setModal2Open] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [form] = Form.useForm<TAccount>();
  const submit = useSubmit();
  const { confirm } = Modal;

  const handleSave = () => {
    form.submit();
  };

  const handleNew = () => {
    form.resetFields();
    setIsEdit(false);
    setModal2Open(true);
  };

  const handleEdit = (data: TAccount) => {
    form.setFieldsValue(data);
    setIsEdit(true);
    setModal2Open(true);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Edit",
      icon: <EditOutlined />,
    },
    {
      key: "2",
      danger: true,
      label: "Delete",
      icon: <DeleteOutlined />,
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Role",
      dataIndex: "roles",
      key: "roles",
      width: "5%",
      render: (_: any, record: any) => (
        <div style={{ textTransform: "capitalize" }}>
          {record?.roles.length > 0 ? record?.roles[0].name : ""}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: "5%",
      render: (_: any, record: any) =>
        record.is_active ? (
          <div style={{ textAlign: "center" }}>Aktif</div>
        ) : (
          <div style={{ textAlign: "center" }}>Tidak Aktif</div>
        ),
    },
    {
      title: "Action",
      key: "action",
      width: "3%",
      render: (_: any, record: any) => (
        <Dropdown
          placement="bottomRight"
          menu={{
            items,
            onClick: ({ key }) => {
              if (key === "1") handleEdit(record);
              else if (key === "2") showDeleteConfirm(record.id);
            },
          }}
        >
          <p style={{ fontWeight: "bold", textAlign: "center" }}>
            <MoreOutlined style={{ fontWeight: "bold", textAlign: "center" }} />
          </p>
        </Dropdown>
      ),
    },
  ];

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Delete User System",
      icon: <ExclamationCircleFilled />,
      centered: true,
      content: "Are you sure you want delete this data user system?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        submit({ action: "delete", id }, { method: "post" });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Users"
              extra={
                <Button
                  type="primary"
                  ghost
                  onClick={handleNew}
                  icon={<PlusCircleOutlined />}
                >
                  Add New User
                </Button>
              }
            >
              <div className="table-responsive">
                <Table
                  dataSource={dataSource.map((dt) => ({
                    ...dt,
                    key: dt.id,
                  }))}
                  columns={columns}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        title="User System"
        centered
        open={modal2Open}
        okText="Save"
        cancelText="Cancel"
        onOk={handleSave}
        onCancel={() => setModal2Open(false)}
      >
        <Form
          form={form}
          size={"middle"}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={(formValues) => {
            submit({ ...formValues }, { method: "POST" });
            setModal2Open(false);
          }}
          autoComplete="off"
        >
          <Form.Item<TAccount> name="id" hidden={true}>
            <Input />
          </Form.Item>

          <Form.Item<TAccount>
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input your name!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<TAccount>
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          {!isEdit && (
            <Form.Item<TAccount>
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password size="middle" />
            </Form.Item>
          )}

          <Form.Item<TAccount>
            label="Role"
            name="role"
            rules={[{ required: true, message: "Please input your role!" }]}
          >
            <Select
              placeholder="Select role"
              allowClear
              size="middle"
              disabled={!!form.getFieldValue("id")}
            >
              <Select.Option value="admin">Admin</Select.Option>
              <Select.Option value="guest">Guest</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item<TAccount> label="Is active ?" name="is_active">
            <Switch defaultChecked />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AccountPage;
