import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  HistoryOutlined,
  MoreOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { useRouteLoaderData, useSubmit } from "react-router-dom";

import { TVehicle } from "../types/TVehicle";
import { TDriver } from "../types/TDriver";
import { getRole } from "../services/localStorage";
import { TOrder } from "../types/TOrder";

const { confirm } = Modal;
const { Text } = Typography;

const VehiclePage: React.FC = () => {
  let { dataSource, drivers } = useRouteLoaderData("vehicle") as {
    dataSource: TVehicle[] | [];
    drivers: TDriver[] | [];
  };

  const submit = useSubmit();
  const [form] = Form.useForm();

  const [modal2Open, setModal2Open] = useState<boolean>(false);
  const [modalHistory, setModalHistory] = useState<boolean>(false);
  const [historyOrders, setHistoryOrders] = useState<TOrder[]>([]);

  const handleSave = () => {
    form.submit();
  };

  const handleNew = () => {
    form.resetFields();
    setModal2Open(true);
  };

  const handleEdit = (data: TVehicle) => {
    form.setFieldsValue(data);
    setModal2Open(true);
  };

  const handleHistory = (data: TVehicle) => {
    setHistoryOrders(data.orders as TOrder[]);
    setModalHistory(true);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Edit",
      icon: <EditOutlined />,
    },
    {
      key: "3",
      label: "History",
      icon: <HistoryOutlined />,
    },
    {
      key: "2",
      danger: true,
      label: "Delete",
      icon: <DeleteOutlined />,
    },
  ];

  const columns = [
    {
      title: "Plat Nomor",
      dataIndex: "license_plate",
      key: "license_plate",
      width: "10%",
      render: (_: any, record: any) => (
        <div style={{ textAlign: "center" }}>{record.license_plate}</div>
      ),
    },
    {
      title: "Tipe",
      dataIndex: "type",
      key: "type",
      width: "20%",
    },
    {
      title: "Nomor Casis",
      dataIndex: "chasis_serial",
      key: "chasis_serial",
    },
    {
      title: "Nomor Mesin",
      dataIndex: "engine_serial",
      key: "engine_serial",
    },
    {
      title: "Driver",
      dataIndex: "driver_name",
      key: "driver_id",
      width: "25%",
      render: (_: any, record: any) => <div>{record?.driver?.name}</div>,
    },
    {
      title: "Status",
      dataIndex: "is_ready",
      key: "is_ready",
      width: "10%",
      render: (_: any, record: any) => (
        <Text
          style={{
            color: record.is_ready ? "green" : "red",
          }}
        >
          {record.is_ready ? "Ready" : "On Track"}
        </Text>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "3%",
      render: (_: any, record: any) => (
        <Dropdown
          placement="bottomRight"
          menu={{
            items,
            onClick: ({ key }) => {
              if (key === "1") handleEdit(record);
              else if (key === "2") showDeleteConfirm(record.id);
              else if (key === "3") handleHistory(record);
            },
          }}
        >
          <p style={{ fontWeight: "bold", textAlign: "center" }}>
            <MoreOutlined style={{ fontWeight: "bold", textAlign: "center" }} />
          </p>
        </Dropdown>
      ),
    },
  ];

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Delete Vehicle",
      icon: <ExclamationCircleFilled />,
      centered: true,
      content: "Are you sure you want delete this data vehicle?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        submit({ action: "delete", id }, { method: "post" });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const validateForm = async () => {
    const vehicle = await dataSource.filter(
      (vhc) => vhc.license_plate === form.getFieldsValue().license_plate
    );
    if (vehicle.length > 0) {
      form.setFields([
        {
          name: "license_plate",
          errors: ["Plat Number is already registered"],
        },
      ]);
    } else {
      submit({ ...form.getFieldsValue() }, { method: "POST" });
      setModal2Open(false);
    }
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Vehicles"
              extra={
                getRole() === "ROLE_ADMIN" ? (
                  <Button
                    type="primary"
                    ghost
                    onClick={handleNew}
                    icon={<PlusCircleOutlined />}
                  >
                    Add New Vehicle
                  </Button>
                ) : undefined
              }
            >
              <div className="table-responsive">
                <Table
                  dataSource={dataSource.map((dt) => ({
                    ...dt,
                    key: dt.id,
                  }))}
                  columns={
                    getRole() === "ROLE_ADMIN"
                      ? columns
                      : columns.splice(0, columns.length - 1)
                  }
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        title="Vehicle"
        centered
        open={modal2Open}
        okText="Save"
        cancelText="Cancel"
        onOk={handleSave}
        onCancel={() => setModal2Open(false)}
      >
        <Form
          form={form}
          size="small"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={() => {
            validateForm();
          }}
          autoComplete="off"
        >
          <Form.Item<TVehicle> name="id" hidden={true}>
            <Input />
          </Form.Item>

          <Form.Item<TVehicle>
            label="Plat Nomor"
            name="license_plate"
            rules={[
              {
                required: true,
                message: "Please input your license plate!",
              },
            ]}
          >
            <Input maxLength={10} />
          </Form.Item>

          <Form.Item<TVehicle>
            label="Nomor Casis"
            name="chasis_serial"
            rules={[
              {
                required: true,
                len: 17,
                message: "Please input your chasis_serial!",
              },
            ]}
          >
            <Input maxLength={17} />
          </Form.Item>

          <Form.Item<TVehicle>
            label="Nomor Mesin"
            name="engine_serial"
            rules={[
              {
                required: true,
                len: 17,
                message: "Please input your engine_serial!",
              },
            ]}
          >
            <Input maxLength={17} />
          </Form.Item>

          <Form.Item<TVehicle>
            label="Tipe"
            name="type"
            rules={[{ required: true, message: "Please input your type!" }]}
          >
            <Select placeholder="Select type vehicle" allowClear>
              <Select.Option value="Dutro 10 Ton">Dutro 10 Ton</Select.Option>
              <Select.Option value="Engkel 20 Ton">Engkel 20 Ton</Select.Option>
              <Select.Option value="Curah 30 Ton">Curah 30 Ton</Select.Option>
            </Select>
          </Form.Item>

          <Divider />
          <Form.Item<TVehicle>
            label="Driver"
            name="driver_id"
            // rules={[{ required: true, message: "Please input your driver!" }]}
          >
            <Select placeholder="Select driver" allowClear>
              {drivers?.map((driver: any) => (
                <Select.Option key={driver?.id} value={driver?.id}>
                  {driver?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="History"
        centered
        open={modalHistory}
        cancelButtonProps={{ style: { display: "none" } }}
        okText="Close"
        onOk={() => setModalHistory(false)}
        onCancel={() => setModalHistory(false)}
        width={1000}
      >
        <div className="table-responsive">
          <Table
            dataSource={historyOrders.map((dt) => ({
              ...dt,
              key: dt.id,
            }))}
            columns={[
              {
                title: "Date",
                dataIndex: "pickup",
                key: "pickup",
                width: "10%",
              },
              {
                title: "Code",
                dataIndex: "code",
                key: "code",
                width: "10%",
                render: (_: any, record: any) => <div>#{record.code}</div>,
              },
              {
                title: "Sender",
                dataIndex: "sender",
                key: "sender",
              },
              {
                title: "Receiver",
                dataIndex: "receiver",
                key: "receiver",
              },
            ]}
            className="ant-border-space"
          />
        </div>
      </Modal>
    </>
  );
};

export default VehiclePage;
