import { LoaderFunctionArgs, json, redirect } from "react-router-dom";
import { isAuthenticated } from "../services/authenticationService";

import * as OrderAction from "../actions/order.action";
import * as VehicleAction from "../actions/vehicle.action";

import { TOrder } from "../types/TOrder";
import { TVehicle } from "../types/TVehicle";

export async function orderLoader({ request }: LoaderFunctionArgs) {
  if (!isAuthenticated()) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }

  const orders: any = await OrderAction.getDatatables();
  const vehicles: any = await VehicleAction.getDatatables();

  return json({
    orders: orders.data as TOrder[],
    vehicles: vehicles.data as TVehicle[],
  });
}
