import { AxiosResponse } from "axios";
import httpCommon from "./http-common";
import { TAccount } from "../types/TAccount";
import { LoaderFunctionArgs, redirect } from "react-router-dom";

export async function userAction({ request }: LoaderFunctionArgs) {
  const data: any = Object.fromEntries(await request.formData());

  if (data?.action === "delete") {
    await deleteUser(data?.id);
  } else {
    data.id =
      data.id === undefined || data.id === "undefined" ? undefined : data.id;
    data.id === undefined || data.id === "undefined"
      ? await createUser(data)
      : await updateUser(data);
  }

  return redirect("/users");
}

export const getDatatables = async () => {
  const response: AxiosResponse = await httpCommon.get("/user");
  return response.data as TAccount[];
};

export const createUser = async (data: TAccount) => {
  return await httpCommon.post<TAccount>("/user", data);
};

export const updateUser = async (data: TAccount) => {
  return await httpCommon.put<TAccount>("/user/" + data.id, data);
};

export const deleteUser = async (id: string) => {
  return await httpCommon.delete<TAccount>("/user/" + id);
};
