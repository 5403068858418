import { LoaderFunctionArgs, json, redirect } from "react-router-dom";
import { isAuthenticated } from "../services/authenticationService";

import * as UserAction from "../actions/user.action";

import { TAccount } from "../types/TAccount";

export async function accountLoader({ request }: LoaderFunctionArgs) {
  if (!isAuthenticated()) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }
  const users: any = await UserAction.getDatatables();
  return json({
    dataSource: users.data as TAccount[],
  });
}
