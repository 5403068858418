import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Modal,
  Row,
  Table,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  MoreOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { TDriver } from "../types/TDriver";
import { useRouteLoaderData, useSubmit } from "react-router-dom";
import { getRole } from "../services/localStorage";

const { confirm } = Modal;

const DriverPage: React.FC = () => {
  let { drivers } = useRouteLoaderData("driver") as {
    drivers: TDriver[] | [];
  };

  const submit = useSubmit();
  const [modal2Open, setModal2Open] = useState<boolean>(false);

  const [form] = Form.useForm();

  const handleSave = () => {
    form.submit();
  };

  const handleNew = () => {
    form.resetFields();
    setModal2Open(true);
  };

  const handleEdit = (data: TDriver) => {
    form.setFieldsValue(data);
    setModal2Open(true);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Edit",
      icon: <EditOutlined />,
    },
    {
      key: "2",
      danger: true,
      label: "Delete",
      icon: <DeleteOutlined />,
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      width: "10%",
    },
    {
      title: "Action",
      key: "action",
      width: "3%",
      render: (_: any, record: any) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items,
            onClick: ({ key }) => {
              if (key === "1") handleEdit(record);
              else if (key === "2") showDeleteConfirm(record.id);
            },
          }}
        >
          <p style={{ fontWeight: "bold", textAlign: "center" }}>
            <MoreOutlined style={{ fontWeight: "bold", textAlign: "center" }} />
          </p>
        </Dropdown>
      ),
    },
  ];

  const validateForm = async () => {
    const driver = await drivers.filter(
      (driver) => driver.contact === form.getFieldsValue().contact
    );
    if (driver.length > 0) {
      form.setFields([
        {
          name: "contact",
          errors: ["Contact is already registered"],
        },
      ]);
    } else {
      submit({ ...form.getFieldsValue() }, { method: "POST" });
      setModal2Open(false);
    }
  };

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Delete Driver",
      icon: <ExclamationCircleFilled />,
      centered: true,
      content: "Are you sure you want delete this data driver?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        submit(
          { action: "delete", id },
          { method: "post", action: "/drivers" }
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Drivers"
              extra={
                getRole() === "ROLE_ADMIN" ? (
                  <Button
                    type="primary"
                    ghost
                    onClick={handleNew}
                    icon={<PlusCircleOutlined />}
                  >
                    Add New Driver
                  </Button>
                ) : undefined
              }
            >
              <div className="table-responsive">
                <Table
                  dataSource={drivers.map((dt) => ({
                    ...dt,
                    key: dt.id,
                  }))}
                  columns={
                    getRole() === "ROLE_ADMIN"
                      ? columns
                      : columns.splice(0, columns.length - 1)
                  }
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        title="Driver"
        centered
        open={modal2Open}
        okText="Save"
        cancelText="Cancel"
        onOk={handleSave}
        onCancel={() => setModal2Open(false)}
      >
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={(formValues) => {
            validateForm();
          }}
          autoComplete="off"
        >
          <Form.Item<TDriver> name="id" hidden={true}>
            <Input />
          </Form.Item>

          <Form.Item<TDriver>
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input your name!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<TDriver>
            label="Address"
            name="address"
            rules={[{ required: true, message: "Please input your address!" }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <Form.Item<TDriver>
            label="Contact"
            name="contact"
            rules={[{ required: true, message: "Please input your contact!" }]}
          >
            <Input
              onKeyDown={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              maxLength={13}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default DriverPage;
