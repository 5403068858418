import axios from "axios";

export default axios.create({
  baseURL: "//api.v-track.biz.id", //"http://localhost:3000", //
  headers: {
    "Content-type": "application/json",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});
