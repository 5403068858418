import { Button, Card, Form, Input, Row } from "antd";
import React from "react";
import { useLocation, useSubmit } from "react-router-dom";

import logo from "../assets/image.png";

const LoginPage: React.FC = () => {
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  let from = params.get("from") || "/";

  const submit = useSubmit();

  const [form] = Form.useForm();

  const handleLogin = () => {
    form.submit();
  };

  return (
    <Row
      justify="center"
      align="middle"
      style={{ minHeight: "100vh", display: "flex" }}
    >
      <Card
        className="card-signup header-solid h-full ant-card m-0"
        bordered={false}
        actions={[
          <Button
            key={0}
            type="primary"
            htmlType="button"
            style={{ width: "90%" }}
            onClick={handleLogin}
          >
            SIGN IN
          </Button>,
        ]}
      >
        <div className="sign-up-gateways">
          <div
            className="brand"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <img
              src={logo}
              alt=""
              style={{ width: "50px", height: "auto", marginLeft: 20 }}
            />
            <span style={{ fontSize: "30px", fontWeight: "normal" }}>
              pedisi
            </span>
          </div>
        </div>
        {/* <div>
          {loaderData?.error ? (
          <Alert
            message="The username or password is incorrect"
            type="error"
            style={{ marginBottom: 20 }}
            closable
          />
           ) : null} 
        </div> */}
        <Form
          form={form}
          layout="vertical"
          className="row-col"
          onFinish={(formValues: any) => {
            submit({ ...formValues }, { method: "POST" });
          }}
          initialValues={{ redirectTo: from }}
        >
          <Form.Item name="redirectTo">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            className="username"
            label="Username / Email"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            className="username"
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password placeholder="Password" autoComplete="off" />
          </Form.Item>
        </Form>
      </Card>
    </Row>
  );
};

export default LoginPage;
