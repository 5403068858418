export const removeEmptyValuesFromObject = (object: Record<string, any>) => {
  Object.keys(object).forEach((key) => {
    const isEmptyEntry = Boolean(object[key]) === false;
    if (isEmptyEntry) {
      delete object[key];
    }
  });
  return object;
};

export function generateOrderId(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
