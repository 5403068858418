import { Card, Col, List, Row, Typography } from "antd";
import {
  AimOutlined,
  CarOutlined,
  TeamOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import React from "react";
import { useRouteLoaderData } from "react-router-dom";

import { Map } from "../components/map";

import { TVehicle } from "../types/TVehicle";
import { TDriver } from "../types/TDriver";
import { TOrder } from "../types/TOrder";

const { Title } = Typography;

const DashboardPage: React.FC = () => {
  let { orders, vehicles, drivers } = useRouteLoaderData("dashboard") as {
    orders: TOrder[] | [];
    vehicles: TVehicle[] | [];
    drivers: TDriver[] | [];
  };

  const count = [
    {
      today: "Vehicles",
      title: vehicles?.length || 0,
      icon: <CarOutlined />,
    },
    {
      today: "Drivers",
      title: drivers?.length || 0,
      icon: <TeamOutlined />,
    },
    {
      today: "Orders",
      title: orders?.length || 0,
      icon: <ShoppingOutlined />,
    },
  ];

  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{c.today}</span>
                      <Title level={3}>{c.title}</Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">{c.icon}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={18} xl={18}>
            <Map markers={vehicles as TVehicle[]} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Card title="Vehicle">
              <List
                min-width="100%"
                className="header-notifications-dropdown "
                itemLayout="horizontal"
                dataSource={vehicles || ([] as any)}
                renderItem={(item: any) => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.license_plate}
                      description={
                        <p style={{ fontSize: 12, color: "gray" }}>
                          <AimOutlined
                            style={{ color: item.is_ready ? "green" : "red" }}
                          />
                          {` ${item.is_ready ? "last position" : "last from"} ${
                            item.lat | 0
                          }, ${item.lng | 0}`}
                        </p>
                      }
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DashboardPage;
