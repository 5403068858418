import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Dropdown,
  Flex,
  Form,
  Input,
  MenuProps,
  Modal,
  Row,
  Select,
  Space,
  Steps,
  Table,
  TimePicker,
  Typography,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
  MoreOutlined,
  PlusCircleOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useActionData, useRouteLoaderData, useSubmit } from "react-router-dom";

import { TOrder } from "../types/TOrder";
import { TVehicle } from "../types/TVehicle";

import { Map } from "../components/map";

import { generateOrderId } from "../utils";
import { getRole } from "../services/localStorage";
import { TTransit } from "../types/TTransit";
import Paragraph from "antd/es/typography/Paragraph";

const { confirm } = Modal;
const { Text, Title } = Typography;

const OrderPage: React.FC = () => {
  let { orders, vehicles } = useRouteLoaderData("order") as {
    orders: TOrder[] | [];
    vehicles: TVehicle[] | [];
  };

  const action = useActionData() as any;
  console.log("action : ", action?.data?.data);

  const [modalTransit, setModalTransit] = useState<boolean>(false);
  const [modal2Open, setModal2Open] = useState<boolean>(false);
  const [modalMap, setModalMap] = useState<boolean>(false);
  const [modalDetails, setModalDetails] = useState<boolean>(false);
  const [order, setOrder] = useState({} as TOrder);
  const [loaderDate, setLoaderDate] = useState<any>();
  const [loaderTime, setLoaderTime] = useState<any>();

  const [form] = Form.useForm();
  const [formTransit] = Form.useForm();
  const submit = useSubmit();

  useEffect(() => {
    if (action) setOrder(action?.data?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action?.data?.data]);

  const handleSave = () => {
    if (loaderTime) {
      form.setFieldsValue({
        loader: new Date(
          new Date(
            (loaderDate === undefined ? order.pickup : loaderDate) +
              " " +
              loaderTime +
              " +0000"
          ).toLocaleString("en-US", { timeZone: "Asia/Jakarta" })
        ),
      });
    }
    form.submit();
  };

  const handleSaveTransit = () => {
    formTransit.submit();
  };

  const handleAddTransit = () => {
    formTransit.resetFields();
    setModalTransit(true);
  };

  const handleNew = () => {
    const orderId = generateOrderId(10);
    form.resetFields();
    form.setFieldsValue({
      id: undefined,
      code: orderId,
    });
    setOrder({ code: orderId });
    setModal2Open(true);
  };

  const handleEdit = (data: any) => {
    setOrder(data);
    form.setFieldsValue({
      ...data,
      pickup: dayjs(data.pickup, "YYYY-MM-DD"),
      loader: data.loader
        ? dayjs(data.loader?.substring(0, 10), "YYYY-MM-DD")
        : undefined,
    });
    setModal2Open(true);
  };

  const handleDetails = (data: any) => {
    setOrder(data);
    setModalDetails(true);
  };

  const handleSetDeparture = (data: any) => {
    submit(
      {
        id: data.id,
        departure_hours: new Date().toTimeString().substring(0, 8),
      },
      { method: "POST" }
    );
  };

  const handleViewMap = (data: any) => {
    setOrder(data);
    setModalMap(true);

    const script = document.createElement("script");

    script.src = "https://use.typekit.net/foobar.js";
    script.async = true;

    document.body.appendChild(script);
    // setInterval(() => {
    //   navigate(".", { replace: true });
    // }, 1000);
  };

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Delete Order",
      icon: <ExclamationCircleFilled />,
      centered: true,
      content: "Are you sure you want delete this data order?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        submit({ action: "delete", id }, { method: "post", action: "/orders" });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showDeleteTransitConfirm = (id: string) => {
    confirm({
      title: "Delete Point Transit",
      icon: <ExclamationCircleFilled />,
      centered: true,
      content: "Are you sure you want delete this data Point Transit?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        submit(
          { action: "delete-transit", id },
          { method: "post", action: "/orders" }
        );
        setModalTransit(false);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Show Map",
      icon: <PushpinOutlined />,
    },
    {
      key: "2",
      label: "Edit",
      icon: <EditOutlined />,
    },
    {
      key: "3",
      label: "Details",
      icon: <EyeOutlined />,
    },
    {
      key: "4",
      danger: true,
      label: "Delete",
      icon: <DeleteOutlined />,
    },
  ];

  const columns = [
    {
      title: "OrderId",
      dataIndex: "code",
      width: "10%",
      key: "code",
      render: (_: any, record: any) => (
        <div className="author-info">
          <Title level={5}>{record?.createdAt?.substring(0, 10)}</Title>
          <p>{`#${record.code}`}</p>
        </div>
      ),
    },
    {
      title: "Vehicle",
      dataIndex: "vehicle",
      key: "vehicle",
      render: (_: any, record: any) => (
        <div className="author-info">
          <Title level={5}> {record?.vehicle?.type}</Title>
          <p>{record?.vehicle?.license_plate}</p>
        </div>
      ),
    },
    {
      title: "Driver",
      dataIndex: "driver",
      key: "driver",
      render: (_: any, record: any) => (
        <div className="author-info">
          <Title level={5}> {record?.vehicle?.driver?.name}</Title>
          <p>{record?.vehicle?.driver?.contact}</p>
        </div>
      ),
    },
    {
      title: "Sender",
      dataIndex: "sender",
      key: "sender",
      render: (_: any, record: any) => (
        <div className="author-info">
          <Title level={5}> {record?.sender}</Title>
          <p>{record?.sender_phone}</p>
        </div>
      ),
    },
    {
      title: "Receiver",
      dataIndex: "receiver",
      key: "receiver",
      render: (_: any, record: any) => (
        <div className="author-info">
          <Title level={5}> {record?.receiver}</Title>
          <p>{record?.receiver_phone}</p>
        </div>
      ),
    },
    {
      title: "Pick-Up Date",
      dataIndex: "pickup",
      width: "5%",
      key: "pickup",
      render: (_: any, record: any) => (
        <Text>{record?.pickup ? record?.pickup : "-"}</Text>
      ),
    },
    {
      title: "Loader Date",
      dataIndex: "loader",
      width: "5%",
      key: "loader",
      render: (_: any, record: any) => (
        <Text>{record?.loader ? record?.loader?.substring(0, 10) : "-"}</Text>
      ),
    },
    {
      title: "Trip",
      dataIndex: "status_pp",
      width: "5%",
      key: "status_pp",
      render: (_: any, record: any) => (
        <Text>{record?.status_pp ? "Pulang - Pergi" : "Pergi"}</Text>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "5%",
      key: "status",
      render: (_: any, record: any) =>
        record.departure_hours ? (
          <Text
            style={{
              color: record.pickup
                ? record.status
                  ? "orange"
                  : "green"
                : "gray",
            }}
          >
            {record.pickup
              ? record.status
                ? "On Going"
                : "Complete"
              : "Draft"}
          </Text>
        ) : record.vehicle_id === null ? (
          <Text
            style={{
              color: "blue",
            }}
          >
            Vehicle not ready
          </Text>
        ) : (
          <Button
            type="default"
            size="small"
            onClick={() => handleSetDeparture(record)}
            disabled={record.vehicle_id === null}
          >
            Departure Now
          </Button>
        ),
    },
    {
      title: "Action",
      key: "action",
      width: "3%",
      render: (_: any, record: any) => (
        <Dropdown
          placement="bottomRight"
          menu={{
            items: items.filter((item) =>
              item?.key === "1"
                ? item
                : getRole() === "ROLE_ADMIN" &&
                  record.status &&
                  item?.key === "2"
                ? item
                : !record.status && item?.key === "3"
                ? item
                : getRole() === "ROLE_ADMIN" && item?.key === "4"
                ? item
                : undefined
            ),
            onClick: ({ key }) => {
              if (key === "1") handleViewMap(record);
              else if (key === "2") handleEdit(record);
              else if (key === "3") handleDetails(record);
              else if (key === "4") showDeleteConfirm(record.id);
            },
          }}
        >
          <p style={{ fontWeight: "bold", textAlign: "center" }}>
            <MoreOutlined style={{ fontWeight: "bold", textAlign: "center" }} />
          </p>
        </Dropdown>
      ),
    },
  ];

  const onChangeSteps = (value: number) => {
    if (getRole() === "ROLE_ADMIN") {
      order?.transits && formTransit.setFieldsValue(order?.transits[value - 1]);
      setModalTransit(true);
    }
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Orders"
              extra={
                getRole() === "ROLE_ADMIN" ? (
                  <Button
                    type="primary"
                    ghost
                    onClick={handleNew}
                    icon={<PlusCircleOutlined />}
                  >
                    Add New Order
                  </Button>
                ) : undefined
              }
            >
              <div className="table-responsive">
                <Table
                  dataSource={orders.map((order) => ({
                    ...order,
                    key: order.id,
                  }))}
                  columns={columns}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        title="Order shipping"
        centered
        open={modalMap}
        cancelButtonProps={{ style: { display: "none" } }}
        okText="Close"
        onOk={() => setModalMap(false)}
        onCancel={() => setModalMap(false)}
        width={1200}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
          {/* <Card className="card-billing-info" bordered={false}>
            <div className="col-info">
              <Descriptions title="Shipping">
                <Descriptions.Item label="Driver" span={3}>
                  {order?.vehicle?.driver?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Vehicle" span={3}>
                  {order?.vehicle?.type} / {order?.vehicle?.license_plate}
                </Descriptions.Item>
              </Descriptions>
            </div>
            <div className="col-info">
              <Descriptions title="Sender">
                <Descriptions.Item label="Name" span={3}>
                  {order?.sender}
                </Descriptions.Item>
                <Descriptions.Item label="Contact" span={3}>
                  {order?.sender_phone}
                </Descriptions.Item>
                <Descriptions.Item label="Address" span={3}>
                  {order?.sender_address}
                </Descriptions.Item>
              </Descriptions>
            </div>
            <div className="col-info">
              <Descriptions title="Receive">
                <Descriptions.Item label="Name" span={3}>
                  {order?.receiver}
                </Descriptions.Item>
                <Descriptions.Item label="Contact" span={3}>
                  {order?.receiver_phone}
                </Descriptions.Item>
                <Descriptions.Item label="Address" span={3}>
                  {order?.receiver_address}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </Card> */}
          <Row>
            <Col span={18} push={6}>
              <Map
                order={order}
                markers={[{ ...(order.vehicle as TVehicle) }]}
              />
            </Col>
            <Col span={6} pull={18}>
              <Divider />
              <Descriptions>
                <Descriptions.Item label="Driver" span={3}>
                  {order?.vehicle?.driver?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Vehicle" span={3}>
                  {order?.vehicle?.type} / {order?.vehicle?.license_plate}
                </Descriptions.Item>
                <Descriptions.Item label="Trip" span={3}>
                  {order?.departure_hours_back ? "Pulang" : "Pergi"}
                </Descriptions.Item>
              </Descriptions>
              <Divider>
                {getRole() === "ROLE_ADMIN" && (
                  <Button
                    shape="round"
                    icon={<PlusCircleOutlined />}
                    size="small"
                    onClick={handleAddTransit}
                  >
                    Add Transit
                  </Button>
                )}
              </Divider>
              <Steps
                progressDot
                onChange={onChangeSteps}
                current={1 + (order?.transits?.length || 0)}
                direction="vertical"
                items={[
                  {
                    title: <Text type="secondary">{order.sender}</Text>,
                    description: order.sender_phone,
                    disabled: true,
                  },
                  ...(order?.transits?.map((trans) => ({
                    title: (
                      <Paragraph>
                        {trans.name}{" "}
                        {getRole() === "ROLE_ADMIN" && (
                          <Text keyboard type="warning">
                            Edit
                          </Text>
                        )}
                      </Paragraph>
                    ),
                    description: trans.phone,
                  })) || []),
                  {
                    title: <Text type="secondary">{order.receiver}</Text>,
                    description: order.receiver_phone,
                    disabled: true,
                  },
                ]}
              />
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        title={`Order #${order.code}`}
        centered
        open={modal2Open}
        okText="Save"
        cancelText="Cancel"
        onOk={handleSave}
        onCancel={() => setModal2Open(false)}
        width={1000}
      >
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={(formValues) => {
            submit(
              {
                ...formValues,
                pickup:
                  formValues.pickup === undefined ||
                  formValues.pickup === "undefined"
                    ? undefined
                    : formValues.pickup.format("YYYY-MM-DD"),
              },
              { method: "POST" }
            );
            setModal2Open(false);
          }}
          autoComplete="off"
        >
          <Form.Item<TOrder> name="id" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item<TOrder> name="loader" hidden={true}>
            <Input />
          </Form.Item>
          {order.departure_hours ? (
            <Space
              direction="vertical"
              size="middle"
              style={{ display: "flex" }}
            >
              <Form.Item<TOrder> name="vehicle_id" hidden={true}>
                <Input />
              </Form.Item>
              <Card className="card-billing-info" bordered={false}>
                <div className="col-info">
                  <Descriptions title="Shipping">
                    <Descriptions.Item label="Driver" span={3}>
                      {order?.vehicle?.driver?.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Vehicle" span={3}>
                      {order?.vehicle?.type} / {order?.vehicle?.license_plate}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
                <div className="col-info">
                  <Descriptions title="Sender">
                    <Descriptions.Item label="Name" span={3}>
                      {order?.sender}
                    </Descriptions.Item>
                    <Descriptions.Item label="Contact" span={3}>
                      {order?.sender_phone}
                    </Descriptions.Item>
                    <Descriptions.Item label="Address" span={3}>
                      {order?.sender_address}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
                <div className="col-info">
                  <Descriptions title="Receive">
                    <Descriptions.Item label="Name" span={3}>
                      {order?.receiver}
                    </Descriptions.Item>
                    <Descriptions.Item label="Contact" span={3}>
                      {order?.receiver_phone}
                    </Descriptions.Item>
                    <Descriptions.Item label="Address" span={3}>
                      {order?.receiver_address}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </Card>
              <Row>
                <Col span={24}>
                  <Card>
                    <Title level={5}>Loader Date</Title>
                    <Space style={{ display: "flex", marginBottom: 8 }}>
                      <DatePicker
                        format="YYYY-MM-DD"
                        onChange={(value: any, dateString: any) => {
                          setLoaderDate(dateString);
                        }}
                        minDate={dayjs(order.pickup, "YYYY-MM-DD")}
                        defaultValue={dayjs(order.pickup, "YYYY-MM-DD")}
                        style={{ margin: 0, padding: 0 }}
                      />
                      <TimePicker
                        onChange={(time: any, timeString: any) => {
                          setLoaderTime(timeString);
                        }}
                        defaultValue={dayjs(order.departure_hours, "HH:mm:ss")}
                        style={{ margin: 0, padding: 0 }}
                      />
                    </Space>
                  </Card>
                </Col>
              </Row>
            </Space>
          ) : (
            <>
              <Form.Item<TOrder> name="code" hidden={true}>
                <Input />
              </Form.Item>
              <Row style={{ marginTop: 20 }}>
                <Col span={12}>
                  <Form.Item<TOrder>
                    label="Name Sender"
                    name="sender"
                    rules={[
                      { required: true, message: "Please input your name!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item<TOrder>
                    label="Address Sender"
                    name="sender_address"
                    rules={[
                      {
                        required: true,
                        message: "Please input your sender_address!",
                      },
                    ]}
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>

                  <Form.Item<TOrder>
                    label="Contact Sender"
                    name="sender_phone"
                    rules={[
                      { required: true, message: "Please input your contact!" },
                    ]}
                  >
                    <Input
                      onKeyDown={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      maxLength={13}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Coordinate"
                    style={{ marginBottom: 0 }}
                    rules={[{ required: true }]}
                  >
                    <Form.Item
                      name="sender_lat"
                      rules={[{ required: true }]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input placeholder="Latitude" />
                    </Form.Item>
                    <Form.Item
                      name="sender_lng"
                      rules={[{ required: true }]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px",
                      }}
                    >
                      <Input placeholder="Longitude" />
                    </Form.Item>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item<TOrder>
                    label="Name Receiver"
                    name="receiver"
                    rules={[
                      {
                        required: true,
                        message: "Please input your receiver!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item<TOrder>
                    label="Address Receiver"
                    name="receiver_address"
                    rules={[
                      { required: true, message: "Please input your address!" },
                    ]}
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>

                  <Form.Item<TOrder>
                    label="Contact Receiver"
                    name="receiver_phone"
                    rules={[
                      { required: true, message: "Please input your contact!" },
                    ]}
                  >
                    <Input
                      onKeyDown={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      maxLength={13}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Coordinate"
                    style={{ marginBottom: 0 }}
                    rules={[{ required: true }]}
                  >
                    <Form.Item
                      name="receiver_lat"
                      rules={[{ required: true }]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input placeholder="Latitude" />
                    </Form.Item>
                    <Form.Item
                      name="receiver_lng"
                      rules={[{ required: true }]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px",
                      }}
                    >
                      <Input placeholder="Longitude" />
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={12}>
                  <Form.Item<TOrder> label="Vehicle" name="vehicle_id">
                    <Select
                      placeholder="Select vehicle"
                      disabled={!!order.departure_hours}
                      allowClear
                      options={vehicles?.map((vehicle: any) => ({
                        value: vehicle.id,
                        label:
                          vehicle?.type + " - (" + vehicle?.license_plate + ")",
                        disabled: !vehicle?.is_ready,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item<TOrder> label="Pickup date" name="pickup">
                    <DatePicker format="YYYY-MM-DD" disabled={false} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item<TOrder>
                    name="status_pp"
                    valuePropName="checked"
                    // label="is Round Trip"
                    wrapperCol={{ offset: 8, span: 16 }}
                  >
                    <Checkbox>Round Trip</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Modal>
      <Modal
        title={`Order #${order.code}`}
        centered
        open={modalDetails}
        cancelButtonProps={{ style: { display: "none" } }}
        okText="Close"
        onOk={() => setModalDetails(false)}
        onCancel={() => setModalDetails(false)}
        width={1000}
      >
        <Card className="card-billing-info" bordered={false}>
          <div className="col-info">
            <Descriptions title="Shipping">
              <Descriptions.Item label="Driver" span={3}>
                {order?.vehicle?.driver?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Vehicle" span={3}>
                {order?.vehicle?.type} / {order?.vehicle?.license_plate}
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div className="col-info">
            <Descriptions title="Sender">
              <Descriptions.Item label="Name" span={3}>
                {order?.sender}
              </Descriptions.Item>
              <Descriptions.Item label="Contact" span={3}>
                {order?.sender_phone}
              </Descriptions.Item>
              <Descriptions.Item label="Address" span={3}>
                {order?.sender_address}
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div className="col-info">
            <Descriptions title="Receive">
              <Descriptions.Item label="Name" span={3}>
                {order?.receiver}
              </Descriptions.Item>
              <Descriptions.Item label="Contact" span={3}>
                {order?.receiver_phone}
              </Descriptions.Item>
              <Descriptions.Item label="Address" span={3}>
                {order?.receiver_address}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Card>
      </Modal>
      <Modal
        title={`Transit Point`}
        centered
        open={modalTransit}
        okText="Save"
        cancelText="Cancel"
        onOk={handleSaveTransit}
        onCancel={() => setModalTransit(false)}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            {formTransit.getFieldValue("id") !== undefined ? (
              <Flex justify={"space-between"}>
                <Button
                  danger
                  onClick={() =>
                    showDeleteTransitConfirm(formTransit.getFieldValue("id"))
                  }
                >
                  Delete
                </Button>
                <Flex gap="middle">
                  <CancelBtn />
                  <OkBtn />
                </Flex>
              </Flex>
            ) : (
              <>
                <CancelBtn />
                <OkBtn />
              </>
            )}
          </>
        )}
      >
        <Form
          form={formTransit}
          layout="vertical"
          onFinish={(formValues: TTransit) => {
            console.log("formValues : ", formValues);

            submit(
              {
                ...formValues,
              },
              { method: "POST" }
            );
            setModalTransit(false);
          }}
          autoComplete="off"
        >
          <Form.Item
            name="action"
            hidden={true}
            initialValue="transit"
          ></Form.Item>
          <Form.Item
            name="order_id"
            hidden={true}
            initialValue={order.id}
          ></Form.Item>
          <Form.Item<TTransit> name="id" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item<TTransit>
            label="Name "
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your receiver!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item<TTransit>
            label="Address "
            name="address"
            rules={[{ required: true, message: "Please input your address!" }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <Form.Item<TTransit>
            label="Contact"
            name="phone"
            rules={[{ required: true, message: "Please input your contact!" }]}
          >
            <Input
              onKeyDown={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              maxLength={13}
            />
          </Form.Item>
          <Form.Item
            label="Coordinate"
            style={{ marginBottom: 0 }}
            rules={[{ required: true }]}
          >
            <Form.Item<TTransit>
              name="lat"
              rules={[{ required: true }]}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
              }}
            >
              <Input placeholder="Latitude" />
            </Form.Item>
            <Form.Item<TTransit>
              name="lng"
              rules={[{ required: true }]}
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
                margin: "0 8px",
              }}
            >
              <Input placeholder="Longitude" />
            </Form.Item>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default OrderPage;
