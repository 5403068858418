import { AxiosResponse } from "axios";
import { TDriver } from "../types/TDriver";
import httpCommon from "./http-common";
import { LoaderFunctionArgs, redirect } from "react-router-dom";

export async function driverAction({ request }: LoaderFunctionArgs) {
  const data: any = Object.fromEntries(await request.formData());

  if (data?.action === "delete") {
    await deleteDriver(data?.id);
  } else {
    data.id =
      data.id === undefined || data.id === "undefined" ? undefined : data.id;
    data.id === undefined || data.id === "undefined"
      ? await createDriver(data)
      : await updateDriver(data);
  }

  return redirect("/drivers");
}

export const getDatatables = async () => {
  const response: AxiosResponse = await httpCommon.get("/driver");
  return response.data as TDriver[];
};

export const createDriver = async (data: TDriver) => {
  return await httpCommon.post<TDriver>("/driver", data);
};

export const updateDriver = async (data: TDriver) => {
  return await httpCommon.put<TDriver>("/driver/" + data.id, data);
};

export const deleteDriver = async (id: string) => {
  return await httpCommon.delete<TDriver>("/driver/" + id);
};
