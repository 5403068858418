import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import logo from "../../assets/image.png";
import {
  AppstoreOutlined,
  CarTwoTone,
  TableOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { getRole } from "../../services/localStorage";

const Sidenav: React.FC = () => {
  const menus = [
    {
      key: 1,
      label: (
        <NavLink to="/">
          <AppstoreOutlined className="icon" />
          <span className="label">Dashboard</span>
        </NavLink>
      ),
    },
    {
      key: 2,
      label: (
        <NavLink to="/orders">
          <TableOutlined className="icon" />
          <span className="label">Orders</span>
        </NavLink>
      ),
    },
    {
      key: 3,
      label: (
        <NavLink to="/drivers">
          <TeamOutlined className="icon" />
          <span className="label">Drivers</span>
        </NavLink>
      ),
    },
    {
      key: 4,
      label: (
        <NavLink to="/vehicles">
          <CarTwoTone className="icon" />
          <span className="label">Vehicles</span>
        </NavLink>
      ),
    },
    {
      key: 5,
      label: "Account Pages",
      className: "menu-item-header",
    },
    {
      key: 6,
      label: (
        <NavLink to="/users">
          <UserOutlined className="icon" />
          <span className="label">Users</span>
        </NavLink>
      ),
    },
  ];
  return (
    <>
      <div className="brand">
        <img
          src={logo}
          alt=""
          style={{ width: "50px", height: "auto", marginLeft: 20 }}
        />
        <span style={{ fontSize: "30px", fontWeight: "lighter" }}>pedisi</span>
      </div>
      <hr />
      <Menu
        theme="light"
        mode="inline"
        items={
          getRole() === "ROLE_ADMIN" ? menus : menus.splice(0, menus.length - 2)
        }
      />
    </>
  );
};

export default Sidenav;
