import { LoaderFunctionArgs, json, redirect } from "react-router-dom";
import { isAuthenticated } from "../services/authenticationService";

import * as VehicleAction from "../actions/vehicle.action";
import * as DriverAction from "../actions/driver.action";

import { TVehicle } from "../types/TVehicle";
import { TDriver } from "../types/TDriver";

export async function vehicleLoader({ request }: LoaderFunctionArgs) {
  if (!isAuthenticated()) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }
  const vehicles: any = await VehicleAction.getDatatables();
  const drivers: any = await DriverAction.getDatatables();
  return json({
    dataSource: vehicles.data as TVehicle[],
    drivers: drivers.data as TDriver[],
  });
}
