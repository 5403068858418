import { LoaderFunctionArgs, json, redirect } from "react-router-dom";
import { isAuthenticated } from "../services/authenticationService";

import * as DriverAction from "../actions/driver.action";
import * as OrderAction from "../actions/order.action";
import * as VehicleAction from "../actions/vehicle.action";
import { TOrder } from "../types/TOrder";
import { TDriver } from "../types/TDriver";
import { TVehicle } from "../types/TVehicle";

export async function dashboardLoader({ request }: LoaderFunctionArgs) {
  if (!isAuthenticated()) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }
  const drivers: any = await DriverAction.getDatatables();
  const orders: any = await OrderAction.getDatatables();
  const vehicles: any = await VehicleAction.getDatatables();
  return json({
    drivers: drivers.data as TDriver[],
    orders: orders.data as TOrder[],
    vehicles: vehicles.data as TVehicle[],
  });
}
