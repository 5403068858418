import { getAccessToken } from "./localStorage";
import * as AuthAction from "../actions/auth.action";

export interface AuthPayload {
  username: string;
  password: string;
}

export const authenticate = async (data: AuthPayload) => {
  try {
    return {
      status: true,
      data: await AuthAction.login(data),
    };
  } catch (e: any) {
    return { status: false, data: "Something went wrong" };
  }
};

export const isAuthenticated = (): boolean => {
  return getAccessToken() ? true : false;
};
