import { useEffect } from "react";
import { Row, Col, Breadcrumb, Dropdown, MenuProps } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { Link, useSubmit } from "react-router-dom";
import { getUser } from "../../services/localStorage";

const profile = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      fill="#111827"
    ></path>
  </svg>,
];

function Header({ name, subName }: any) {
  const submit = useSubmit();
  useEffect(() => window.scrollTo(0, 0));

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <Link to="/change-password">Changes Password</Link>,
    },
    {
      type: "divider",
    },
    {
      key: "2",
      danger: true,
      label: "Logout",
    },
  ];

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          <Breadcrumb
            items={[
              { title: <HomeOutlined /> },
              {
                title:
                  name.replace("/", "") === ""
                    ? "dashboard"
                    : name.replace("/", ""),
              },
            ]}
          />
          <div className="ant-page-header-heading">
            <span
              className="ant-page-header-heading-title"
              style={{ textTransform: "capitalize" }}
            >
              {subName.replace("/", "") === ""
                ? "Dashboard"
                : subName.replace("/", "")}
            </span>
          </div>
        </Col>
        <Col span={24} md={18} className="header-control">
          <Dropdown
            menu={{
              items,
              onClick: ({ key }) => {
                if (key === "2")
                  submit(null, {
                    method: "post",
                    encType: "application/json",
                    action: "/logout",
                  });
              },
            }}
            placement="bottomLeft"
          >
            <a
              href="#pablo"
              className="ant-dropdown-link btn-sign-in"
              onClick={(e) => e.preventDefault()}
            >
              <span
                style={{ fontWeight: "normal", marginRight: 5, color: "#000" }}
              >
                @{getUser()?.split("@")[0]}
              </span>
              {profile}
            </a>
          </Dropdown>
        </Col>
      </Row>
    </>
  );
}

export default Header;
