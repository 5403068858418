import { LoaderFunctionArgs, json, redirect } from "react-router-dom";
import { isAuthenticated } from "../services/authenticationService";

import * as DriverAction from "../actions/driver.action";
import { TDriver } from "../types/TDriver";

export async function driverLoader({ request }: LoaderFunctionArgs) {
  if (!isAuthenticated()) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }
  const drivers: any = await DriverAction.getDatatables();
  return json({ drivers: drivers.data as TDriver[] });
}
